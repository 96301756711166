<template>
	<div class="form-group">
		<div class="row">
			<div class="col-12 mb-3 h4">Kolor tekstu</div>
			<div class="col-12">
				<div class="form-check form-check-inline">
					<input v-model="settings.textColor" value="#fff" class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" >
					<label class="form-check-label" for="inlineRadio1"><div class="border p-3" style="background: #fff"></div></label>
				</div>
				<div class="form-check form-check-inline">
					<input v-model="settings.textColor" value="#bc100d" class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" >
					<label class="form-check-label" for="inlineRadio2"><div class="border p-3" style="background: #bc100d"></div></label>
				</div>
				<div class="form-check form-check-inline">
					<input v-model="settings.textColor" value="#004b96" class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" >
					<label class="form-check-label" for="inlineRadio3"><div class="border p-3" style="background: #004b96"></div></label>
				</div>
				<div class="form-check form-check-inline">
					<input v-model="settings.textColor" value="#000" class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" >
					<label class="form-check-label" for="inlineRadio4"><div class="border p-3" style="background: #000"></div></label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'color',
		props: ['settings'],
		data() {
            return {
			}
		},
		watch: {
		},
    }
</script>