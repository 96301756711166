<template>
	<Nav/>
	<form action="">
		<div class="container-fluid px-5 my-5">

			<div class="row mb-4 d-flex align-items-end justify-content-between">
				<div class="col-6">
					<router-link :to="{name: 'AdminForms'}" class="btn btn-primary">Wstecz</router-link>
				</div>

				<div class="col-auto">
					<template v-if="isUpdateFrom">
						<button type="button" class="btn btn-info" @click="update">Zaktualizuj formularz</button>
						<button type="button" class="btn btn-danger ml-3" @click="destroy">Usuń formularz</button>
					</template>
					<button v-else class="btn btn-info" @click.prevent="save">Zapisz formularz</button>
				</div>
			</div>

			<ul class="nav nav-tabs mb-5">
				<li class="nav-item">
					<button type="button" @click="show = 'title'" class="nav-link" :class="{active: show === 'title'}">Tytuł</button>
				</li>
				<li class="nav-item">
					<button type="button" @click="show = 'agreements'" class="nav-link" :class="{active: show === 'agreements'}">Zgody</button>
				</li>
				<li class="nav-item">
					<button type="button" @click="show = 'content'" class="nav-link" :class="{active: show === 'content'}">Treść</button>
				</li>
				<li class="nav-item">
					<button type="button" @click="show = 'summary'" class="nav-link" :class="{active: show === 'summary'}">Podsumowanie</button>
				</li>
			</ul>

			<div class="row">
				<div class="col-12">
					<div v-show="show === 'title'">
						<div class="row">
							<div class="col-12 form-group mb-4">
								<label for="form-language" class="h4">Formularz na pierwszym kroku</label><br>
								<input class="mr-2" type="checkbox" v-model="form.content.settings.hideForm"><span>Ukryj formularz</span>
							</div>

							<div class="col-lg-12 col-xl-6 form-group mb-4">
								<label for="form-language" class="h4">Język formularza</label>
								<select id="form-language" class="form-control" v-model="form.content.settings.language" required>
									<option value="pl">Polski</option>
									<option value="ru">Rosyjski</option>
								</select>
							</div>

							<div class="col-lg-6 col-xl-4 form-group mb-4">
								<label for="" class="h4">Przypnij formularz w innym języku</label>
								<select id="" class="form-control" v-model="form.content.settings.otherLanguage.token">
									<option value="">brak</option>
									<option v-for="form in forms" :value="form.slug">
										{{ form.title }}
									</option>
								</select>
							</div>
							<div class="col-lg-6 col-xl-2 form-group mb-4">
								<label for="" class="h4">Język tłumaczenia</label>
								<select id="" class="form-control" v-model="form.content.settings.otherLanguage.language">
									<option value="pl">Polski</option>
									<option value="ru">Rosyjski</option>
								</select>
							</div>

						</div>

						<div class="form-group mb-4">
							<label for="form-title" class="h4">Tytuł formularza</label>
							<input id="form-title" type="text" class="form-control" v-model="form.title" @change="slugGenerate" required>
						</div>

            <div class="form-group mb-4">
              <label for="form-description" class="h4">Treść pod formularzem</label>
              <input id="form-description" type="text" class="form-control" v-model="form.content.description" required>
            </div>

						<div class="form-group mb-4">
							<label for="form-title" class="h4">Adres formularza</label>
							<input id="form-slug" type="text" class="form-control" v-model="form.slug" required>
						</div>

            <div class="form-group mb-4">
              <label for="form-title" class="h4">Miejsce pozyskania</label>
              <input id="form-slug" type="text" class="form-control" v-model="form.content.settings.source">
            </div>

            <color class="mb-4" :settings="form.content.settings"></color>
						<bgColor class="mb-4" :background="form.content.settings.textBackground"></bgColor>

						<div class="form-group mt-5">
							<div class="row">
								<div class="col-12 mb-3">
									<label for="form-description">Grafika w rozmiarze 920px na 260px</label>
									<div>
										<div v-if="form.content.graphics" class="position-relative w-75 mb-3 position-relative">
											<BtnDelete @click="form.content.graphics = false"/>
											<div class="position-absolute p-5" style="pointer-events: none">
												<div class="form-heading-inner" style="width: 70%" :style="{color: form.content.settings.textColor}">
													<h1 :style="headingStyle" class="mb-2 d-inline-block">{{ form.title }}</h1><br>
													<p :style="headingStyle" class="mb-0 d-inline-block">{{ form.content.description }}</p>
												</div>
											</div>
											<img class="img-fluid rounded" v-if="form.content.graphics" :src="$store.state.backendUrl + 'storage/' + form.content.graphics[0]['token']" alt="">
										</div>
										<UploadFile v-else class="small" :imgSize="[920,260]" :single="true" :element="graphicsEntity" @change="form.content.graphics = $event"></UploadFile>
									</div>
								</div>
							</div>
						</div>

						<div class="form-group mt-5">
							<div class="row">
								<div class="col-12 mb-3">
									<label for="form-description">Watermark</label>
									<div>
										<div v-if="form.content.watermark" class="position-relative w-75 mb-3 position-relative">
											<BtnDelete @click="form.content.watermark = false"/>
											<img class="img-fluid rounded" v-if="form.content.watermark" :src="$store.state.backendUrl + 'storage/' + form.content.watermark[0]['token']" alt="">
										</div>
										<UploadFile v-else class="small" :single="true" :element="graphicsEntity" @change="form.content.watermark = $event"></UploadFile>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div v-show="show === 'agreements'" class="row">
						<div class="col-12 mb-4 h4">Zgody</div>
						<div class="col-12 mb-3" v-for="(element, index) in form.content.agreements" :key="element.id">
							<div class="mb-2 d-flex">
								<input type="text" class="form-control" v-model="element.title">
								<input type="text" class="form-control ml-3 w-25" placeholder="IPresso agreement ID" v-model="element.ipressoId">
							</div>
							<div class="d-flex justify-content-between">
								<div class="custom-control custom-checkbox mb-3">
									<input type="checkbox" v-model="element.required" class="custom-control-input" :id="element.id">
									<label class="custom-control-label" :for="element.id">Pole obowiązkowe?</label>
								</div>
								<button class="btn btn-outline-secondary" type="button" @click="removeAgreement(index)">Usuń</button>
							</div>
						</div>
						<div class="col-12 text-right">
							<button @click="addAgreement" class="btn btn-outline-secondary" type="button">Dodaj</button>
						</div>

						<div class="col-12 mb-4 h4">Obowiązek informacyjny</div>
						<div class="col-12">
							<div class="form-group mb-3">
								<label for="">Treść (przed rozwinięciem)</label>
								<input v-model="form.content.information.excerpt" type="text" class="form-control">
							</div>
							<div class="form-group">
								<label for="">Treść (po rozwinięciu)</label>
								<textarea v-model="form.content.information.more" class="form-control" name="" id="" cols="30" rows="10"></textarea>
							</div>
						</div>
					</div>

					<div v-show="show === 'content'" class="row">
						<div class="col-2 position-sticky h-100" style="top:20px;">
							<h3 class="mb-3 h5">Zainteresowanie produktem:</h3>
							<div class="mb-4">
								<select name="interestProduct" class="form-control" v-model="form.content.interestProduct">
									<option value="inz">INŻ</option>
									<option value="jsm">JSM</option>
									<option value="lic">LIC</option>
									<option value="mba">MBA</option>
									<option value="sp">SP</option>
									<option value="sum">SUM</option>
									<option value="SUMplus">SUMplus</option>
									<option value="szkolenia">SZKOLENIA</option>
								</select>
							</div>

							<h3 class="mb-3 h5">Ilość pytań na stronie:</h3>
							<div class="mb-4">
								<input name="questionsPerPage" type="number" class="form-control" v-model="form.content.settings.questionsPerPage">
							</div>

							<h3 class="mb-3 h5">Typ quizu</h3>
							<div class="mb-4">
								<select name="quizType" class="form-control" v-model="form.content.settings.quizType">
									<option value="classic">Klasyczny</option>
									<option value="likert">Skala Likerta</option>
								</select>
							</div>

							<h3 class="mb-3 h5">Dodaj pole:</h3>
							<div class="d-flex flex-column">
								<!--<button type="button" class="btn btn-primary mb-2" @click="addField('text')">Text</button>-->
								<!--<button type="button" class="btn btn-primary mb-2" @click="addField('checkbox')">Checkbox</button>-->
								<button v-if="form.content.settings.quizType === 'classic'" type="button" class="btn btn-primary mb-2" @click="addField('checkbox-group')">Checkbox group</button>
								<button v-if="form.content.settings.quizType === 'classic'" type="button" class="btn btn-primary mb-2" @click="addField('radio')">Radio</button>
								<button v-if="form.content.settings.quizType === 'likert'" type="button" class="btn btn-primary mb-2" @click="addField('likert')">Likert</button>
								<button v-if="form.content.settings.quizType === 'classic'" type="button" class="btn btn-primary mb-2" @click="addField('radio-image')">Radio ze grafikami</button>
								<!--<button type="button" class="btn btn-primary mb-2" @click="addField('file')">File</button>-->
							</div>
						</div>
						<div class="col offset-1">

							<template v-for="(element, index) in elements">
								<template v-if="form.content.settings.quizType !== 'likert'">
									<FieldText v-if="element.type === 'text'" :ref="'field' + element.id" :key="element.id"
											:value="element.value"
											@change="setValue($event, element)"
											@remove="removeElement(index)"
											@up="moveElement(true, index)"
											@down="moveElement(false, index)">
									</FieldText>

									<FieldCheckbox v-if="element.type === 'checkbox'" :ref="'field' + element.id" :key="element.id"
											:value="element.value"
											@change="setValue($event, element)"
											@remove="removeElement(index)"
											@up="moveElement(true, index)"
											@down="moveElement(false, index)">
									</FieldCheckbox>

									<FieldCheckboxGroup v-if="element.type === 'checkbox-group'" :ref="'field' + element.id" :key="element.id"
											:form="form"
											:value="element.value"
											@change="setValue($event, element)"
											@remove="removeElement(index)"
											@up="moveElement(true, index)"
											@down="moveElement(false, index)">
									</FieldCheckboxGroup>

									<FieldRadio v-if="element.type === 'radio'" :ref="'field' + element.id" :key="element.id"
											:form="form"
											:value="element.value"
											@change="setValue($event, element)"
											@remove="removeElement(index)"
											@up="moveElement(true, index)"
											@down="moveElement(false, index)">
									</FieldRadio>

									<FieldRadioImage v-if="element.type === 'radio-image'" :ref="'field' + element.id" :key="element.id"
											:form="form"
											:value="element.value"
											@change="setValue($event, element)"
											@remove="removeElement(index)"
											@up="moveElement(true, index)"
											@down="moveElement(false, index)">
									</FieldRadioImage>

									<FieldFile v-if="element.type === 'file'" :ref="'field' + element.id" :key="element.id"
											:value="element.value"
											@change="setValue($event, element)"
											@remove="removeElement(index)"
											@up="moveElement(true, index)"
											@down="moveElement(false, index)">
									</FieldFile>
								</template>

								<template v-if="form.content.settings.quizType === 'likert'">
									<FieldLikert v-if="element.type === 'likert'" :ref="'field' + element.id" :key="element.id"
										:form="form"
										:value="element.value"
										@change="setValue($event, element)"
										@remove="removeElement(index)"
										@up="moveElement(true, index)"
										@down="moveElement(false, index)">
								</FieldLikert>
								</template>
							</template>
						</div>
					</div>

					<div v-show="show === 'summary'" class="row">
						<Summary :summary="form.content.summary"/>
					</div>

					<small class="d-none" @click="getValuesFromComponents"><pre style="overflow: scroll; height: 400px">{{ form }}</pre></small>
				</div>
			</div>

		</div>
	</form>
</template>

<script>
	import Nav from '@/components/Nav';
    import FieldText from '@/components/FieldText';
    import FieldCheckbox from '@/components/FieldCheckbox';
    import FieldCheckboxGroup from '@/components/FieldCheckboxGroup';
    import FieldRadio from '@/components/FieldRadio';
    import FieldLikert from '@/components/FieldLikert';
    import FieldRadioImage from '@/components/FieldRadioImage';
    import FieldFile from '@/components/FieldFile';
    import color from '@/components/form-create/color';
    import BgColor from '@/components/form-create/BgColor';
    import Helpers from '@/mixins/Helpers';
    import {agreement} from '@/models/agreementsModel';
    import BtnDelete from '@/components/form-create/BtnDelete';
    import Summary from '@/components/form-create/SummaryVariant';

    import UploadFile from '@/components/UploadFile';

    export default {
        name: 'FormCreate',
        mixins: [Helpers],
        components: { FieldText,
			FieldCheckbox,
			FieldCheckboxGroup,
			FieldRadio,
            FieldLikert,
            FieldRadioImage,
			FieldFile,
			Nav,
            UploadFile,
            color,
            BgColor,
            BtnDelete,
            Summary,
		},
        data() {
            return {
                show: 'title',
                graphicsEntity: {
                    value: {
                        anyExtension: false,
						extensions: [
                            {
                                title: "jpg",
                                checked: true
                            },
                            {
                                title: "jpeg",
                                checked: true
                            },
                            {
                                title: "png",
                                checked: true
                            },
                        ]
					},
				},
                isUpdateFrom: undefined,
            }
        },
        computed: {
            elements() {
                return this.form.content.elements;
            },
            form() {
                return this.$store.state.form;
            },
			forms() {
              	return this.$store.state.forms;
			},
            headingStyle() {
                if( this.form.content.settings.textBackground.color === 'transparent' ) return;
                return {
                    'background-color': `rgb(${this.form.content.settings.textBackground.color}, ${this.form.content.settings.textBackground.opacity / 100})`,
                }
            }
        },
        created() {
            document.body.classList.add('admin');
            this.$store.commit('cleanForms');

            this.$store.dispatch('index');

            this.isUpdateFrom = this.$route.params.token ? true : false;

            if(this.isUpdateFrom) {
                this.$store.dispatch('get', {token: this.$route.params.token});
            }
        },
        methods: {
            separateContentFields(elements) {
                let likerts = [];
                let another = [];

                for (let i = 0; i < elements.length; i++) {
                    const element = elements[i];

                    if (element.type === 'likert') {
                        likerts.push(element);
                    } else {
                        another.push(element);
                    }
                }

                return this.form.content.settings.quizType === 'likert' ? likerts : another;
			},
			formValidate() {
                if( !document.querySelector('form').checkValidity() ) {
                    var fields = document.querySelectorAll('input');
                    for (let i = 0; i < fields.length; i++) {
                        if(!fields[i].checkValidity()) {
                            console.log(fields[i].validationMessage, fields[i]);
                            fields[i].style.border = '1px solid red';
                            fields[i].reportValidity()
                        }
                    }
                    alert('wypełnij wymagane pola');
                    return false;
                }
                return true;
			},
            update() {
                if( !this.formValidate() ) return;
                this.getValuesFromComponents();

                if( this.form.content.elements.length === 0 ) {
                    alert('Nie możesz dodać quizu bez pytań');
                    return
                }

                const data = {
                    token: this.form.token,
                    title: this.form.title,
                    slug: this.form.slug,
                    content: this.form.content
                };

                data.content.elements = this.separateContentFields(this.form.content.elements);

				// console.log(data);
                // return;

                delete data.content.token;
                delete data.content.title;
                delete data.content.slug;

                this.$store.dispatch('update', data);
            },
            save() {
                if( !this.formValidate() ) return;
                this.getValuesFromComponents();

				if( this.form.content.elements.length === 0 ) {
				    alert('Nie możesz dodać quizu bez pytań');
					return
				}

                const data = this.form;
                data.content.elements = this.separateContentFields(this.form.content.elements);

                // console.log(this.form);
                this.$store.dispatch('save', this.form);
            },
            destroy() {
                if(!confirm('Czy na pewno chcesz usunąć formularz?')) return;
                this.$store.dispatch('destroy', {token: this.form.token});
            },
            getValuesFromComponents() {
                const refs = this.$refs;
                for( const index in refs ) {
                    if(refs[index]) {
                        refs[index].emitField();
                    }
				}
            },
            setValue(field, element) {
                element.value = field;
            },
            moveElement(top, index) {
                Array.prototype.move = function (from, to) {
                    this.splice(to, 0, this.splice(from, 1)[0]);
                };
                if(top && index === 0) return;
                top ? this.elements.move(index, index - 1) : this.elements.move(index, index + 1);
            },
            removeElement(index) {
                this.elements.splice(index, 1);
            },
            addField(type) {
                this.elements.push({
                    type: type,
                    value: null,
                    id: this.makeToken()
                });
            },

            addAgreement() {
				this.form.agreements.push(agreement())
            },

            removeAgreement(index) {
                this.form.agreements.splice(index, 1);
			},

			slugGenerate() {
                const title = this.form.title;

                if (this.form.slug && !confirm('Czy zmienić również adres formularza?')) return;

                this.form.slug = this.strToSlug(title);
			},
        }
    }
</script>

<style lang="scss" scoped>
	.form-heading-inner h1, .form-heading-inner p{
		display: inline-block;
		padding: 5px 10px;
	}
</style>
