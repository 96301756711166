<template>
	<div class="form-group">
		<div class="row">
			<div class="col-12 mb-3 h4">Kolor tła pod tekstem</div>
			<div class="col-12 mb-2">
				<div class="form-check form-check-inline">
					<input v-model="background.color" value="transparent" class="form-check-input" type="radio" name="bg-color-Options" id="bg-color-0">
					<label class="form-check-label" for="bg-color-0">
						<div class="border"><div class="p-3" :style="calcOpacity" style="background: transparent; display: block"></div></div>
					</label>
				</div>
				<div class="form-check form-check-inline">
					<input v-model="background.color" value="255,255,255" class="form-check-input" type="radio" name="bg-color-Options" id="bg-color-1">
					<label class="form-check-label" for="bg-color-1">
						<div class="border"><div class="p-3" :style="calcOpacity" style="background: #fff; display: block"></div></div>
					</label>
				</div>
				<div class="form-check form-check-inline">
					<input v-model="background.color" value="188,16,13" class="form-check-input" type="radio" name="bg-color-Options" id="bg-color-2">
					<label class="form-check-label" for="bg-color-2">
						<div class="border"><div class="p-3" :style="calcOpacity" style="background: #bc100d; display: block"></div></div>
					</label>
				</div>
				<div class="form-check form-check-inline">
					<input v-model="background.color" value="0,75,150" class="form-check-input" type="radio" name="bg-color-Options" id="bg-color-3">
					<label class="form-check-label" for="bg-color-3">
						<div class="border"><div class="p-3" :style="calcOpacity" style="background: #004b96; display: block"></div></div>
					</label>
				</div>
				<div class="form-check form-check-inline">
					<input v-model="background.color" value="0,0,0" class="form-check-input" type="radio" name="bg-color-Options" id="bg-color-4">
					<label class="form-check-label" for="bg-color-4">
						<div class="border"><div class="p-3" :style="calcOpacity" style="background: #000; display: block"></div></div>
					</label>
				</div>
			</div>
			<div class="col-3 mt-2">
				<div class="form-group">
					<label for="customRange2" class="h4">Krycie tła</label>
					<div class="d-flex">
						<div class="w-75">
							<input v-model="background.opacity" type="range" class="custom-range" min="0" max="100" id="customRange2">
						</div>
						<div class="w-25 ml-3">{{ background.opacity }}%</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'BgColor',
		props: ['background'],
		data() {
            return {
			}
		},
		computed: {
            calcOpacity() {
                return {
                    opacity: this.background.opacity / 100
				}
			}
		}
    }
</script>