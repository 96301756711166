<template>
	<div class="bg-light mt-5">
		<div class="container py-3 d-flex align-items-center justify-content-end">
			<a class="mr-3" target="_blank" href="https://www.facebook.com/UniwersytetDolnoslaskiDSW"><img src="../assets/002-facebook.png" alt=""></a>
			<a class="mr-3" target="_blank" href="https://www.instagram.com/uniwersytet_dolnoslaski_dsw"><img src="../assets/001-instagram.png" alt=""></a>
			<a class="mr-3" target="_blank" href="https://pl.linkedin.com/school/uniwersytet-dolnoslaski-dsw/"><img src="../assets/003-linkedin.png" alt=""></a>
			<a class="" target="_blank" href="https://www.youtube.com/@uniwersytet_dolnoslaski_DSW"><img src="../assets/005-youtube.png" alt=""></a>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'Footer',
    }
</script>