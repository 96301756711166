<template>

	<div v-if="message && form.content.settings.quizType === 'classic' && summary.percent !== true">
		<div class="card p-3 mb-3">
			<h2 class="mb-5">{{ message.title }}</h2>
			<div v-html="message.content"></div>
		</div>
	</div>

	<div v-if="form.content.settings.quizType === 'likert'">
		<div class="card p-3 mb-3" v-for="item in likert">
			<h2 v-if="item" class="mb-5">{{ item.title }}</h2>
			<div v-if="item" v-html="item.content"></div>
		</div>
	</div>

	<div v-if="summary.percent && summarySorted.length">
		<h2 class="mb-3">Twój wynik to:</h2>
		<div class="row">
			<div class="col-md-6" v-for="item in summarySorted">
				<div class="card p-3 mb-3">
					<h3 class="mb-3">{{ Math.round( (item.value / form.content.elements.length) * 100) }}% {{ item.title }}</h3>
					<div v-html="item.content"></div>
				</div>
			</div>
		</div>
	</div>

	<!--<pre>{{ summary }}</pre>-->
	<!--<pre>{{ form.content.settings }}</pre>-->
	<!--<pre>{{ form.content.elements }}</pre>-->
	<!--<pre>{{ summarySorted }}</pre>-->

</template>

<script>
	import {sortBy} from 'lodash';

    export default {
        name: 'FormSummary',
		props: ['summary', 'elements', 'form'],
		data() {
			return {
                message: undefined,
				likert: undefined,
                summarySorted: [],
			}
		},
		mounted() {
          	this.test();
		},
		methods: {
            addSummary(id) {
                for (let i = 0; i < this.summary.summary.length; i++) {
                    if(this.summary.summary[i].id === id) {
                        this.summary.summary[i].value = this.summary.summary[i].value ? this.summary.summary[i].value += 1 : 1;
                        return;
                    }
                }
			},
            addSummaryLikert(item) {
				for (let i = 0; i < item.summary.length; i++) {
                    const itemSummaryId = item.summary[i];

                    for (let j = 0; j < this.summary.summary.length; j++) {
						const summaryId = this.summary.summary[j].id;

						if (itemSummaryId === summaryId) {
						    if(!this.summary.summary[j].likert) this.summary.summary[j].likert = 0;
                            this.summary.summary[j].likert += item.value;
						}
                    }
				}
			},
			test() {
                for (let i = 0; i < this.elements.length; i++) {
					const item = this.elements[i];

                    if (item.type === 'likert') {
                        this.addSummaryLikert(item.value)
                    }
                    if (item.type === 'radio') {
                        const selected = item.value.value;

                        for (let j = 0; j < item.value.values.length; j++) {
                            const answear = item.value.values[j];

                            if (answear.title === selected) {
                                this.addSummary(answear.summary);
                            }
                        }
                    }
                    if (item.type === 'radio-image') {
                        const selected = item.value.value;

                        for (let j = 0; j < item.value.values.length; j++) {
                            const answear = item.value.values[j];

                            if (answear.id === selected) {
                                this.addSummary(answear.summary);
                            }
                        }
                    }
                    if (item.type === 'checkbox-group') {
                        for (let j = 0; j < item.value.values.length; j++) {
                            if (item.value.values[j].value) {
                                this.addSummary(item.value.values[j].summary);
                            }
                        }
                    }
                }
				
                this.getBestSummary();
			},
            getBestSummary() {
                let best = _.sortBy(this.summary.summary, ['value']).reverse();
                let bestLikert = _.sortBy(this.summary.summary, ['likert']).reverse();

                this.summarySorted = best;

                this.message = best[0];
                this.likert = [
                    bestLikert[0],
                    bestLikert[1],
                    bestLikert[2],
				];
            },
		}
    }
</script>