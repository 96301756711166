<template>
	<Nav :form="form"/>

	<div class="form-wrapper pt-5 pb-5" :style="watermark">
		<div v-if="form" class="container" :class="{'splash-screen-bg': splashScreen}">
		<div class="row f-flex justify-content-center">

			<div v-if="splashScreen" class="col-md-10 splash-screen">
				<img class="pt-5 img-fluid" src="../assets/splash3.png" alt="">
				<h1 class="mb-4 mt-5">{{ form.title }}</h1>
				<button @click="nextStep" class="btn btn-primary">{{ lang.start }}</button>
			</div>

			<div v-if="!splashScreen" class="col-md-10">

				<div class="form-heading d-flex align-items-center mb-5" :style="formHeadingStyle">
					<div class="form-heading-inner" :style="formHeadingInnerStyle">
						<h1 :style="headingStyle" class="mb-4">{{ form.title }}</h1><br>
						<p :style="headingStyle" class="mb-0">{{ form.content.description }}</p>
					</div>
				</div>

				<!--<pre class="position-fixed w-25 d-none" style="left: 0; top: 100px; overflow: scroll; height: 90vh"><small>{{ form }}</small></pre>-->

				<Loader v-if="loader"></Loader>

				<div v-if="formSend">
					<FormSummary v-if="form.content.summary.type === 'range'" :summary="form.content.summary" :elements="form.content.elements" :form="form" />

					<div v-if="form.content.summary.type === 'points'">
						<h2>{{ pointsSummary.title }}</h2>
						<p v-html="pointsSummary.content"></p>
					</div>

					<div v-if="form.content.summary.type === 'answers'">

						<div v-if="form.content.summary.summary.length" v-html="form.content.summary.summary[0].content"></div>

						<h2 v-if="correctCount === result.length">{{ lang.thx.correct }}</h2>
						<h2 v-else>{{ lang.thx.bad }}</h2>
						<h3 class="my-4">{{ lang.thx.score }} {{ correctCount }} / {{ result.length }}</h3>
						<p class="mb-4">{{ lang.thx.summary }}</p>

						<template v-for="item in result">
							<div v-if="item.correct" class="card mb-3 rounded-0 border-success">
								<div class="card-header">
									{{ lang.question }} {{ item.step }}
								</div>
								<div class="card-body">
									<h5 class="card-title font-weight-normal">{{ item.title }}</h5>

									<p v-if="item.feedback" class="card-text text-success">{{ item.feedback}}</p>
									<p v-else class="card-text text-success">{{ lang.correctAnswer }}</p>

									<table class="table table-bordered">
										<tr>
											<th class="w-50">{{ lang.yourAnswer }}</th>
										</tr>
										<tr>
											<td><div v-html="item.answear"></div></td>
										</tr>
									</table>
								</div>
							</div>

							<div v-else class="card mb-3 rounded-0 border-danger">
								<div class="card-header">
									{{ lang.question }} {{ item.step }}
								</div>
								<div class="card-body">
									<h5 class="card-title font-weight-normal">{{ item.title }}</h5>
									<p v-if="item.feedback" class="card-text text-danger">{{ item.feedback}}</p>
									<p v-else class="card-text text-danger">{{ lang.badAnswer }}</p>

									<table class="table table-bordered border-danger">
										<tr>
											<th class="w-50">{{ lang.yourAnswer }}</th>
											<th class="w-50">{{ lang.correctAnswer2 }}</th>
										</tr>
										<tr>
											<td><div v-html="item.answear"></div></td>
											<td><div v-html="item.correctAnswear"></div></td>
										</tr>
									</table>
								</div>
							</div>
						</template>
					</div>
				</div>

				<form v-if="!formSend">
					<div class="wrapper">

						<div v-if="step === 0 && !form.content.settings.hideForm" class="row mb-5">
							<div class="col-lg-4 mb-4 mb-lg-0">
								<h3 v-html="lang.contactData"></h3>
							</div>
							<div class="col">
								<template v-for="element in contact.value.values">
									<div class="row align-items-center mb-4">
										<label :for="id(element.id)" class="col-md-4 col-form-label text-md-right">{{ element.value.title }}</label>
										<div class="col-md">
											<input :v-validate-group="'contact'" v-validate="validateRules(element)" :name="element.id"
													v-model="element.value.value" type="text"
													class="form-control" :id="id(element.id)">
											<div v-if="error(element.id)" class="invalid-feedback d-block">
												{{ error(element.id) }}
											</div>
										</div>
									</div>
								</template>

								<template v-for="element in form.content.agreements">
									<div class="row">
										<div class="col-8 offset-md-4 mb-3">
											<div class="custom-control custom-checkbox">
												<input type="checkbox" class="custom-control-input"
														:v-validate-group="'contact'"
														v-validate="element.required ? 'required' : ''"
														v-model="element.value"
														:name="element.id" :id="id(element.id)">
												<label class="custom-control-label" :for="id(element.id)">{{ element.title }}</label>
											</div>
											<div v-if="error(element.id)" class="invalid-feedback d-block">
												{{ error(element.id) }}
											</div>
										</div>
									</div>
								</template>

								<div class="row">
									<div class="col-8 offset-md-4 mb-3">
										<p class="small">{{ lang.personalDataAdmin }}</p>
									</div>
								</div>

								<div class="row">
									<div class="col-8 offset-md-4 mb-3">
										<button @click="showInformation = !showInformation" :class="{open: showInformation}" class="btn-arrow btn btn-link p-0 mb-2" type="button">{{ form.content.information.excerpt }}</button>
										<p v-if="showInformation" class="small">{{ form.content.information.more }}</p>
									</div>
								</div>
							</div>
						</div>

						<div v-if="step !== 0" class="row">
							<div class="col-12">
								<!--<h3>Ankieta</h3>-->
							</div>
							<div class="col">

								<template v-for="(chunk, index) in formChunked">
									<template v-for="(element, chunkIndex) in chunk">
										<div v-if="step === index + 1">

											<template v-if="form.content.settings.quizType !== 'likert'">
												<div v-if="element.type === 'text'" class="row align-items-center mb-5">
													<label :for="id(element.id)" class="col-12 mb-3">{{ element.value.title }}</label>
													<div class="col-12">
														<input v-validate="'required'" :name="element.id"
																:v-validate-group="'step' + (index + 1)"
																v-model="element.value.value"
																type="text" class="form-control" :id="id(element.id)">
														<div v-if="error(element.id)" class="invalid-feedback d-block">
															{{ error(element.id) }}
														</div>
													</div>
												</div>

												<div v-if="element.type === 'checkbox'" class="row align-items-center mb-5">
													<div class="col-12">
														<div class="custom-control custom-checkbox">
															<input type="checkbox" class="custom-control-input"
																	:v-validate-group="'step' + (index + 1)"
																	v-validate="element.value.required ? 'required' : ''"
																	v-model="element.value.value"
																	:name="element.id" :id="id(element.id)">
															<label class="custom-control-label" :for="id(element.id)">{{ element.value.title }}</label>
														</div>
														<div v-if="error(element.id)" class="invalid-feedback d-block">
															{{ error(element.id) }}
														</div>
													</div>
												</div>

												<div v-if="element.type === 'checkbox-group'" class="row mb-5">
													<label :for="id(element.id)" class="col-12 mb-3">{{ element.value.title }}</label>
													<div class="col-12">
														<div v-for="supElement in element.value.values" class="mb-1 custom-control custom-checkbox">
															<input v-validate="'required'" :name="element.id"
																	:v-validate-group="'step' + (index + 1)"
																	v-model="supElement.value" type="checkbox"
																	class="custom-control-input" :id="id(supElement.id)">
															<label class="custom-control-label" :for="id(supElement.id)">{{ supElement.title }}</label>
														</div>
														<div v-if="error(element.id)" class="invalid-feedback d-block">
															{{ error(element.id) }}
														</div>
													</div>
												</div>

												<div v-if="element.type === 'radio'" class="row mb-5">
													<label :for="id(element.id)" class="col-12 mb-3">{{ element.value.title }}</label>

													<div v-if="element.value.image" class="col-12 mb-3">
														<img class="img-fluid" :src="$store.state.backendUrl + 'storage/' + element.value.image[0]['token']" alt="">
													</div>

													<div class="col-12">
														<div v-for="supElement in element.value.values" class="mb-1 custom-control custom-radio">
															<input v-validate="'required'" :name="element.id" v-model="element.value.value"
																	:v-validate-group="'step' + (index + 1)"
																	:value="supElement.title" type="radio" class="custom-control-input"
																	:id="id(supElement.id)">
															<label class="custom-control-label" :for="id(supElement.id)">{{ supElement.title }}</label>
														</div>
														<div v-if="error(element.id)" class="invalid-feedback d-block">
															{{ error(element.id) }}
														</div>
													</div>
												</div>

												<div v-if="element.type === 'radio-image'" class="row mb-5">
													<label :for="id(element.id)" class="col-12 mb-3">{{ element.value.title }}</label>

													<div v-if="element.value.image" class="col-12 mb-3">
														<img class="img-fluid" :src="$store.state.backendUrl + 'storage/' + element.value.image[0]['token']" alt="">
													</div>

													<div class="col-12">
														<div v-for="supElement in element.value.values" class="mb-1 custom-control custom-radio custom-radio-image">
															<input v-validate="'required'" :name="element.id" v-model="element.value.value"
																	:v-validate-group="'step' + (index + 1)"
																	:value="supElement.id" type="radio" class="custom-control-input"
																	:id="id(supElement.id)">
															<label class="custom-control-label" :for="id(supElement.id)">
																<img class="img-fluid" :src="$store.state.backendUrl + 'storage/' + supElement.image[0]['token']" alt="">
															</label>
														</div>
														<div v-if="error(element.id)" class="invalid-feedback d-block">
															{{ error(element.id) }}
														</div>
													</div>
												</div>

												<div v-if="element.type === 'file'" class="row mb-5">
													<label :for="id(element.id)" class="col-12 mb-3">{{ element.value.title }}</label>
													<div class="col-12">
														<UploadFile :element="element" @change="element.value.value = $event"></UploadFile>
													</div>
												</div>
											</template>

											<template v-if="form.content.settings.quizType === 'likert'">
												<div v-if="element.type === 'likert'" class="row mb-5">
												<label :for="id(element.id)" class="col-12 mb-3">{{ element.value.title }}</label>
												<div class="col-12">
													<div class="row d-flex align-items-center">
														<div class="col-3 small text-right">👎&nbsp;&nbsp;&nbsp;{{ lang.lickertDown}}</div>
														<div v-for="item in [1,2,3,4,5]" class="col d-flex flex-column align-items-center justify-content-center text-center">
															<span class="small">{{ item }}</span>
															<div class="custom-control custom-radio ml-2">
																<input type="radio" class="custom-control-input" :value="item" v-model="element.value.value" :id="id(element.id)+item" :name="id(element.id)">
																<label class="custom-control-label" :for="id(element.id)+item"></label>
															</div>
														</div>
														<div class="col-3 small">{{ lang.lickertUp}}&nbsp;&nbsp;&nbsp;👍</div>
													</div>
												</div>
											</div>
											</template>

										</div>
									</template>
								</template>

							</div>
						</div>

						<div class="row mt-5 justify-content-end">
							<div class="col-12 col-lg-8">
								<button v-if="step === 0" type="button" class="btn btn-primary" @click="nextStep">{{ lang.startTest }}</button>
								<div v-else class="row align-items-center justify-content-end">
									<div class="col-6 col-lg order-0">
										<button v-if="step > 1" type="button" class="btn btn-primary w-100" @click="prevStep">{{ lang.back }}</button>
									</div>

									<div class="col-12 col-lg-4 text-center order-2 order-lg-1 mt-3 mt-lg-0">
										<span class="mx-3">{{ lang.step }} {{ step }} {{ lang.of }} {{ steps }}</span>
									</div>

									<div class="col-6 col-lg order-1">
										<button v-if="step < steps" type="button" class="btn btn-primary w-100" @click="nextStep">{{ lang.next }}</button>
										<button v-if="step === steps" @click.prevent="send" class="btn btn-info w-100">{{ lang.check }}</button>
									</div>
								</div>
							</div>
						</div>

					</div>

				</form>

			</div>
		</div>
	</div>
	</div>

	<Footer/>
</template>

<script>
	import lang from '@/lang/lang';
    import Nav from '@/components/Nav';
    import Footer from '@/components/Footer';
    import Helpers from '@/mixins/Helpers';
    import Validation from '@/mixins/Validation';
    import UploadFile from '@/components/UploadFile';
    import Loader from '@/components/Loader';
    import FormSummary from '@/components/FormSummary';
    import contactModel from '@/models/contactModel';

    export default {
        name: 'Form',
        mixins: [Helpers, Validation],
        components: {
            Nav,
            Footer,
            UploadFile,
            // FormThx,
            Loader,
            FormSummary,
        },
        data() {
            return {
                showInformation: false,
                splashScreen: true,
                step: 0,
                steps: undefined,
                loader: false,
                formSend: false,
                form: undefined,
				contact: undefined,

                pointsSummary: 0,
				result: [],
				correctCount: 0,
				formLanguage: 'pl'
            }
        },
        created() {
            this.$store.dispatch('get', {token: this.$route.params.token}).then(() => {
                this.form = this.$store.state.form;

                if (this.form.content && this.form.content.elements) {
                    // this.steps = this.form.content.elements.length;
                    this.steps = this.formChunked.length;
                }

                this.formLanguage = this.form.content.settings.language;
                this.contact = contactModel(lang(this.formLanguage));
            });
            document.body.classList.remove('admin');
        },
        computed: {
            formChunked() {
                var data = this.form.content.elements;
                const quizType = this.form.content.settings.quizType;

                Object.defineProperty(Array.prototype, 'chunk', {
                    value: function(chunkSize) {
                        var array = this;

                        return [].concat.apply([],
                            array.map(function(elem, i) {
                                 return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
                            })
                        );
                    }
                });
                const questionsPerPage = this.form.content.settings.questionsPerPage;
				return data.chunk( parseInt(questionsPerPage) );
			},
            lang() {
              	return lang(this.formLanguage);
			},
            formHeadingStyle() {
				if (!this.form.content.graphics) return;

                return {
                    'padding': '48px',
                    'background-image': `url(${this.$store.state.backendUrl}storage/${this.form.content.graphics[0]['token']})`
                }
            },
            formHeadingInnerStyle() {
                return {
                    width: this.form.content.graphics ? '70%' : '100%',
                    color: this.form.content.settings.textColor
                }
            },
            headingStyle() {
				if( this.form.content.settings.textBackground.color === 'transparent' ) return;
				return {
                    'background-color': `rgb(${this.form.content.settings.textBackground.color}, ${this.form.content.settings.textBackground.opacity / 100})`,
				}
			},
			watermark() {
                if ( this.step === 0 || !this.form.content.watermark ) return;
                return {
                    'background-image': `url(${this.$store.state.backendUrl}storage/${this.form.content.watermark[0]['token']})`
				}
			}
        },
        methods: {
            id(elementId) {
                return 'el-' + elementId;
            },

            validateRules(element) {
                if(element.type === 'text') return 'required';
                if(element.type === 'email') return 'required|email';
                if(element.type === 'phone') return 'required|phone';
            },

            style(element) {
                if(!element.style) return;
                const style = element.style;
                if(style.width) {
                    return 'flex: 0 0 ' + style.width + '%';
                }else{
                    return 'flex: 0 0 100%';
                }
            },

            send() {
                const $this = this;

                if ( this.validateTest('step' + this.step) ) {
                    this.loader = true;

                    const data = {
                        contact: this.contact,
						agreements: this.form.content.agreements,
                        form: this.form,
                    };

                    if( this.form.content.settings.hideForm ) {
                        if( $this.form.content.summary.type === 'answers' ){
                            $this.test();
                        }
                        if( $this.form.content.summary.type === 'points' ){
                            $this.testPoints();
                        }
                        $this.loader = false;
                        $this.formSend = true;
                        return;
					}

                    this.$store.dispatch('sendLeadForm', {form_token: this.form.token, data: data})
                        .then(function(response){
                            if (response.status === 201) {
                                if( $this.form.content.summary.type === 'answers' ){
                                    $this.test();
                                }
                                if( $this.form.content.summary.type === 'points' ){
                                    $this.testPoints();
                                }
                                $this.loader = false;
                                $this.formSend = true;
                            } else {
                                console.log(response);
                            }
                        })
                }

            },

			test() {
                let elems = this.form.content.elements;
                this.result = [];

                for (let i = 0; i < elems.length; i++) {
                    let correct = true;
                    let answear = [];
                    let correctAnswear = [];
                    let feedback = false;

                    if ( elems[i].type === 'radio' ){
                        const elem = elems[i].value;
                        let correctVal = null;
                        let selected = elem.value;

                        for (let i = 0; i < elem.values.length; i++) {
                            if (elem.values[i].correct) {
                                answear.push('<span>' + elem.value + '</span>');
                                correctAnswear.push('<span>' + elem.values[i].title + '</span>');
                                correctVal = elem.values[i].title;
                            }
                            if (elem.values[i].title === selected && elem.values[i].feedback) {
								feedback = elem.values[i].feedback;
							}
                        }
                        correct = correctVal === selected;
                    }
                    if ( elems[i].type === 'radio-image' ){
                        const elem = elems[i].value;
                        let correctVal = null;
                        let selected = elem.value;

                        for (let i = 0; i < elem.values.length; i++) {
                            if (elem.values[i].correct) {
                                correctAnswear.push(
                                    '<img class="img-fluid" src="' + this.$store.state.backendUrl + 'storage/' + elem.values[i].image[0].token + '" alt="">'
								);
                                correctVal = elem.values[i].id;
                            }
							if( elem.values[i].id === elem.value ) {
                                answear.push('<img class="img-fluid" src="' + this.$store.state.backendUrl + 'storage/' + elem.values[i].image[0].token + '" alt="">');

                                if (elem.values[i].feedback) {
                                    feedback = elem.values[i].feedback;
                                }
							}
                        }
                        correct = correctVal === selected;
                    }
                    if ( elems[i].type === 'checkbox-group' ){
                        const elem = elems[i].value;

                        for (let i = 0; i < elem.values.length; i++) {
                            if (elem.values[i].correct !== elem.values[i].value) {
                                correct = false;
                            }
                            if( elem.values[i].value ){
                                answear.push(elem.values[i].title);

								if (elem.values[i].feedback) {
									feedback = feedback ? feedback + ', ' + elem.values[i].feedback : elem.values[i].feedback;
								}
							}
							if( elem.values[i].correct ) {
                                correctAnswear.push(elem.values[i].title);
							}
                        }
                        elems[i].value.valid = correct
                    }
                    if ( elems[i].type === 'text' ){
                        const elem = elems[i].value;
                        const keys = elem.keys;

                        if( !elem.value ) {
                            correct = false;
                        } else {
                            for (let i = 0; i < keys.length; i++) {
                                if (!elem.value.match(keys[i])) {
                                    correct = false;
                                }
                            }
                        }
                        elems[i].value.valid = correct
                    }

                    let res = {
                        answear: answear.join(', '),
                        correctAnswear: correctAnswear.join(', '),
                        correct: false,
                        title: elems[i].value.title,
                        step: i + 1,
                        feedback: feedback ? feedback : correct ? '' : elems[i].value.feedback,
                    };
                    if(correct) {
                        res.correct = true;
                        this.correctCount++;
                    }
                    this.result.push(res);
                }
                return this.errors.length === 0;
			},

            testPoints() {
                let elems = this.form.content.elements;
                let points = 0;

                for (let i = 0; i < elems.length; i++) {
                    if ( elems[i].type === 'radio' ){
                        const elem = elems[i].value;
                        for (let i = 0; i < elem.values.length; i++) {
                            if ( elem.values[i].title === elem.value ) {
                                points += parseInt(elem.values[i].points);
                            }
                        }
                    }
                    if ( elems[i].type === 'radio-image' ){
                        const elem = elems[i].value;
                        for (let i = 0; i < elem.values.length; i++) {
                            if ( elem.values[i].id === elem.value ) {
                                points += parseInt(elem.values[i].points);
                            }
                        }
                    }
                    if ( elems[i].type === 'checkbox-group' ){
                        const elem = elems[i].value;
                        for (let i = 0; i < elem.values.length; i++) {
                            if ( elem.values[i].value ) {
                                points += parseInt(elem.values[i].points);
                            }
                        }
                    }
                }

                for (let i = 0; i < this.form.content.summary.summary.length; i++) {
                    const item = this.form.content.summary.summary[i];
                    if ( points >= parseInt(item.from) && points <= parseInt(item.to) ) {
                        this.pointsSummary = item;
                        this.runScript(item.script);
                        return;
                    }
                }
            },

            prevStep() {
                if( this.step > 1) {
                    this.step--;
                }
            },

            nextStep() {
                if( this.splashScreen ) {
                    this.splashScreen = false;

                    if( !this.form.content.settings.hideForm ) {
                    	return;
                    }
				}

                if( this.step === 0 && !this.validateTest('contact') ) {
                    return false;
                }
                if( this.step < this.steps && this.validateTest( 'step' + this.step ) ) {
                    this.step++;
                }
            },

            runScript(script) {
            	if (!script) return;
				const s = document.createElement('script');
				s.type = 'text/javascript';
				s.innerHTML = `
					function fireScript() {
						__ipSaveActivity('${script}');
					}
				`;
            	document.body.appendChild(s);
            	setTimeout(fireScript, 500);
            }
        }
    }
</script>

<style lang="scss" scoped>
	.form-heading {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		color: #fff;
		/*height: 260px;*/
		/*padding: 48px;*/
	}
	.form-heading-inner h1, .form-heading-inner p {
		padding: 5px 10px;
		/*background: red;*/
		display: inline-block;
	}
	@media(min-width: 992px) {
		.form-heading {
			height: 260px;
		}
	}

	.splash-screen {
		h1 {
			color: #27458A;
		}
		.btn {
			font-size: 1.2rem;
			font-weight: bold;
			padding: 10px 30px;
			border-radius: 0;
		}
	}

	.splash-screen{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.form-wrapper {
		min-height: calc(100vh - 45px - 2.652rem);
	}

	.btn-arrow:before {
		content: "► ";
	}
	.btn-arrow.open:before {
		content: "▼ ";
	}
</style>