<template>
	<div id="app">
		<ckeditor :editor="editor" @input="change" v-model="value" :config="editorConfig"></ckeditor>
	</div>
</template>

<script>
    // import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import ClassicEditor from '../ckeditor5-31.1.0-3shzsvpr1yip/build/ckeditor';

    export default {
        name: 'app',
		props: ['value'],
        data() {
            return {
                editor: ClassicEditor,
                editorConfig: {
                    toolbar: [ 'heading', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ]
                }
            };
        },
		methods: {
            change() {
				this.$emit('change', this.value);
			}
		}
    }
</script>